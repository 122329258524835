import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "boilerplates"
    }}>{`Boilerplates`}</h1>
    <p>{`These sections provide the WILD's boilerplates guides for both frontend and backend projects.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="/boilerplates/craft-cms" mdxType="Link">Craft CMS</Link>
        <div><small>WILD boilerplate for Craft CMS.</small></div>
      </li>
      <li parentName="ul">
        <Link to="/boilerplates/next-js" mdxType="Link">Next JS</Link>
        <div><small>WILD boilerplate for Next JS based project.</small></div>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      